import Menu from '../components/Menu';
import '../assets/css/Resume.css';
import jobs from '../assets/json/jobs.json';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Resume(props){
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	})
	return(
		<div>
			<Menu page='resume' links={props.links} info={props.info} />
			<div className='mainContent page'>
				<div className='transition'>
					<div className='resume'>
						<div className='pageTitle'>Resume</div>
						<div className='pageInfo'>This resume highlights the developer aspects of the various employers</div>
						<div className='resumeDetails'>
							<div className='resumeLeft'>
								<div className='sectionLabel'>Summary</div>
								<div className='sectionDetails'>
									<div className='detailHeader'>{props.info.name}</div>
									<div className='detail'>Innovative and results-driven Node.JS developer with over {new Date().getFullYear() - 2019} years of experience designing and developing user-centered applications and websites.</div>
								</div>
								<div className='sectionLabel'>Education</div>
								<div className='sectionDetails'>
									<Link className='schoolName' to={props.links.capella} target='_blank'>{props.info.school}</Link>
									<div className='program'>{props.info.degree}</div>
									<div className='years'>{props.info.graduation}</div>
									<div className='gpa'>{props.info.gpa} GPA</div>
									<div className='honors'>{props.info.honors.join(`, `)}</div>
								</div>
								<div className='sectionLabel'>Certficates</div>
								<div className='sectionDetails'>
									{props.certs.map((provider, index) => (
										<>
											<Link key={index} className='certProvider' to={provider.url} title={provider.name} target='_blank'>{provider.name}</Link>
											<div className='certsList'>
												{provider.certs.map((cert, certIndex) => (
													<Link key={certIndex} className='cert' to={cert.url} title={`Click to View ${cert.name} Certificate`} target='_blank'>{cert.name}</Link>
												))}
											</div>
										</>
									))}
								</div>
								<div className='sectionLabel'>Badges</div>
								<div className='sectionDetails'>
									<div className='badges'>
										{props.badges.map((badge, index) => (
											<Link className='badgeLink' to={badge.url} title={`Click to Verify ${badge.name}`} target='_blank'>	
												<img className='badge' alt={badge.name} src={require(`../assets/images/${badge.img}`)} />
											</Link>
										))}
									</div>
								</div>
							</div>
							<div className='resumeRight'>
								<div className='sectionLabel'>Experience</div>
								<div className='sectionDetails'>
									{jobs.map((job, index) => (
										<>
											<Link to={job.url} key={index} className='companyName' target='_blank'>{job.companyName}</Link>
											<div className='years'>{job.years}</div>
											<div className='work'>
												{job.work.map((line, lineIndex) => (
													<li key={lineIndex}>{line}</li>
												))}
											</div>
										</>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
  
export default Resume;