import Menu from '../components/Menu';
import '../assets/css/About.css';
import profileImage from '../assets/images/profile-img.png';
import skillList from '../assets/json/skills.json';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function About(props){
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	});

	return(
		<div>
			<Menu page='about' links={props.links} info={props.info} />
				<div className='mainContent page'>
					<div className='transition'>
						<div className='pageHeader'>
							<div className='pageTitle'>About</div>
							<div className='pageDetails'>
								<div className='about'>I am an experienced developer with over {props.info.years} years experience in creating programs based on specific  requirements. Adaptable, diligent and knowledgeable in JavaScript, Node.js, and Express.js. Detail-oriented, organized and	meticulous employee. I graduated from Capella University in Fall '24 with {props.info.degree} and hold a {props.info.gpa} GPA. I currently develop React.JS and Node.JS applications, websites, and other tools for the <Link className='winterLink' to={props.links.winterClan} target='_blank'>Winter Clan</Link> gaming community.</div>
							</div>
						</div>
						<div className='pageContent'>
							<div className='profile'>
								<img loading='eager' alt='Portrait' src={profileImage} />
							</div>
							<div className='moreInfo'>
								<div className='moreInfoTitle'>{props.info.jobTitle}</div>
								<div className='quote'>“Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle. As with all matters of the heart, you'll know when you find it.” — <div className='author'>Steve Jobs</div></div>
								<div className='moreInfoDetails'>
									<div className='leftSide'>
										<li className='fa-solid fa-chevron-right'>
											<div className='innerText'>
												<strong>School:</strong>
												<Link to={props.links.capella} className='normal school'>{props.info.school}</Link>
											</div>
										</li>
										<li className='fa-solid fa-chevron-right'>
											<div className='innerText'>
												<strong>Website:</strong>
												<div className='normal'>{props.links.dms}</div>
											</div>
										</li>
										<li className='fa-solid fa-chevron-right'>
											<div className='innerText'>
												<strong>City:</strong>
												<div className='normal'>{props.info.city}</div>
											</div>
										</li>
									</div>
									<div className='rightSide'>
										<li className='fa-solid fa-chevron-right'>
											<div className='innerText'>
												<strong>Degree:</strong>
												<div className='normal'>{props.info.degree}</div>
											</div>
										</li>
										<li className='fa-solid fa-chevron-right'>
											<div className='innerText'>
												<strong>Email:</strong>
												<Link to={props.links.email} className='normal email'>{props.info.email}</Link>
											</div>
										</li>
										<li className='fa-solid fa-chevron-right'>
											<div className='innerText'>
												<strong>Discord:</strong>
												<div className='normal'>{props.info.discord}</div>
											</div>
										</li>
									</div>
								</div>
							</div>
						</div>
						<div className='skillChart'>
							<div className='skillTitle'>Skills</div>
							<div className='skills'>
								{skillList.map((skill, index) => (
									<div key={index} className='skillColumn'>
										<div className='skillCategory'>{skill.key}</div>
										<div className='skillList'>
											<div className='twoColumns'>
												<div className='column'>
													{skill.skills.filter((_, i) => i % 2 === 0).map((skill, index) => (
														<Link key={index} className='skill' to={skill.url} target='_blank'>{skill.name}</Link>
													))}
												</div>
												<div className='column'>
													{skill.skills.filter((_, i) => i % 2 !== 0).map((skill, index) => (
														<Link key={index} className='skill' to={skill.url} target='_blank'>{skill.name}</Link>
													))}
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
		</div>
	);
}
  
export default About;